<template>
  <div>
    <!-- <div class="lgTop">
      <span class="lgTop_logo">
        <img src="./assets/img/logo1.png" />
      </span>
      <div class="qrcode-box">
        <a href="javascript:void(0)" class="lgDownBtn app-download"
          >系统操作手册</a
        >
        <p class="show-qrcode" style="display: none">
          <img style="width: 258px; height: 258px" />
        </p>
      </div>
    </div> -->

    <div class="bging">
      <img class="imgs" src="./assets/img/quanjing1.png" />
    </div>
    <div class="nlgBody" style="display: none">
      <div
        class="nlgFm nlgSuss"
        style="
          right: 132px;
          background: none rgb(255, 255, 255);
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          height: 336px;
          width: 458px;
          top: 144px;
        "
      >
        <div
          class="nlgFm_alr"
          style="
            background: url(./images/lgsuss.jpg) no-repeat scroll 139px 29px;
            padding-top: 202px;
          "
        >
          <span>授权成功！</span>页面跳转中，请稍后...
        </div>
      </div>
    </div>

    <div class="nav-text">校园平安一件事管理系统</div>

    <div class="nlgFm nnlgFm wzsty">
      <div class="from-img" style="padding: 0; overflow: hidden">
        <img
          src="./assets/img/school_back.png"
          style="height: 100%; width: auto"
          alt=""
        />
        <!-- <img class="img-shang" src="./assets/img/download.png" /> -->
        <!-- <img class="img-xia" src="./assets/img/school_back.png" alt="" /> -->
      </div>
      <div class="from-div">
        <div class="nlg_hd">
          <ul>
            <li class="nlg_li03">用户登录</li>
            <li class="nlg_li04 sim_tit">SIM认证</li>
          </ul>
        </div>
        <div class="nlg_bd">
          <div class="nlg_bg">
            <el-form ref="fromData" label-width="0px" :model="from_Data">
              <el-form-item
                prop="username"
                :rules="[{ required: true, message: '请输入账号/手机号' }]"
              >
                <el-input
                  placeholder="账号/手机号"
                  prefix-icon="el-icon-user"
                  v-model="from_Data.username"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="login_pwd"
                :rules="[{ required: true, message: '请输入密码' }]"
                class="mima"
              >
                <el-input
                  placeholder="密码"
                  show-password
                  prefix-icon="el-icon-lock"
                  v-model="from_Data.login_pwd"
                >
                  <template slot="append">
                    <div @click="on_retrieve">找回密码</div>
                  </template>
                </el-input>
              </el-form-item>

              <div
                class="nlg_intBox"
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 310px;
                "
              >
                <el-form-item
                  prop="verify"
                  style="width: 170px"
                  :rules="[{ required: true, message: '请输入验证码' }]"
                >
                  <el-input
                    style="width: 170px"
                    placeholder="请输入验证码"
                    prefix-icon="el-icon-lock"
                    v-model="from_Data.verify"
                  ></el-input>
                </el-form-item>
                <div style="width: 140px" @click="on_verification">
                  <img :src="img_verify" style="width: 140px; display: block" />
                </div>
              </div>
            </el-form>

            <div
              style="background: #1a84fc !important; cursor: pointer"
              @click="on_login('fromData')"
              class="nlg_submit"
            >
              立即登录
            </div>
            <!-- <div class="retrievesty">
              <el-link @click="on_retrieve" :underline="false"
                >找回密码</el-link
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="Home-describe">
      <div>
        地址：浙江省杭州市钱塘新区学正街66号&nbsp;|&nbsp;邮编：310018&nbsp;|&nbsp;联系电话：0571-86928099
        0571-86928005&nbsp;|&nbsp;Email:dyb@zjtie.edu.cn
      </div>
      <div>
        @2021浙江经济职业技术学院&nbsp;|&nbsp;版权所有&nbsp;|&nbsp;浙ICP备09058249号
      </div>
    </div>
  </div>
</template>

<script>
import { login, getuserInfo, verify } from "./assets/request/api";

export default {
  data() {
    return {
      img_verify: "",
      from_Data: {
        username: "",
        login_pwd: "",
        verify: ""
      }
    };
  },
  created() {
    sessionStorage.clear();
    this.on_verification();
  },
  methods: {
    on_login(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let { from_Data } = this;
          let that = this;
          login(from_Data).then(res => {
            console.log(res);
            if (res.code == 200) {
              let { data } = res;
              let { token } = res.data;
              localStorage.setItem("token", token);
              sessionStorage.setItem("token", token);
              sessionStorage.setItem("personage", JSON.stringify(data));
              getuserInfo().then(res => {
                let { base, menu, userList } = res.data;
                sessionStorage.setItem("base", JSON.stringify(base));
                sessionStorage.setItem("menu", JSON.stringify(menu));

                sessionStorage.setItem("userList", JSON.stringify(userList));
                let url = menu[0].items[0].url;
                that.$message({
                  message: "登录成功，正在进入，请稍等",
                  type: "success"
                });
                setTimeout(function () {
                  that.$router.push({
                    path: url
                  });
                }, 1000);
              });
            } else {
              this.on_verification();
              that.$message.error(res.msg);
            }
          });
        } else {
          this.on_verification();
          console.log("error submit!!");
          return false;
        }
      });
    },
    on_verification() {
      verify().then(res => {
        const src = window.URL.createObjectURL(res);
        this.img_verify = src;
      });
    },
    on_retrieve() {
      this.$router.push({
        path: "retrievepass"
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "./assets/css/style.css";
/deep/ .el-input--small .el-input__inner {
  height: 47px;
}

.mima {
  /deep/ .el-input__inner {
    padding-right: 80px;
  }
  /deep/ .el-input-group__append {
    background: #ffffff !important;
    color: #1a84fc !important;
    cursor: pointer;
  }
}
.Home-describe {
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  padding: 15px 0 5px 0;
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  div {
    text-align: center;
  }
}

.wzsty {
  border-radius: 10px;
  padding: 30px;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  .from-div {
    padding: 20px;
    border: 1px solid rgb(237, 237, 237);
  }
  .from-img {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
    .img-shang {
      width: 280px;
    }
    .img-xia {
      width: 400px;
      height: auto;
    }
  }
}

.bging {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .imgs {
    width: 100%;
    height: 100%;
  }
}
.bind {
  // min-width: 1085px;
  margin-top: 102px;
}

.lgTop {
  height: 70px;
  width: 1085px;
  margin: 0 auto;
}
.operation-s {
  position: relative;
  height: 574px;
  width: 100%;
  overflow: hidden;
}
.operation {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -960px;
}
.smalimg {
  width: 100%;
  height: 100%;
}

.logos {
  position: absolute;
  top: 80px;
  right: 20%;
  z-index: 999;
  margin: 0 !important;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);
  border-radius: 5px;
  background: #ffffff;
  padding: 10px 20px;
}

.nav-text {
  position: absolute;
  top: 10%;
  left: 50%;
  font-size: 30px;
  color: #ffffff;
  transform: translateX(-50%);
}

.login {
  width: 100%;
  height: 100vh;
  // background: #f7f9ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(./assets/img/main_bg.f4dbc1cd.png) no-repeat;
}

.head {
  background: #ffffff;
  width: 100%;
  height: 70px;
  // border: 1px solid #999999;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  & > div {
    width: 1000px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #4d4d4d;
  }
  .p1 {
    background-image: -webkit-linear-gradient(
      bottom,
      #53a0ff,
      #2f71ff,
      #2f71ff
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.content-div {
  width: 1160px;
  background: #ffffff;
  margin-top: 70px;
  border-radius: 16px;
  // overflow:hidden;
  display: flex;
  justify-content: flex-end;
  & > div {
    background-color: #ffffff;
    width: 100%;
    justify-content: flex-end;
    height: 650px;
    box-shadow: 0 8px 24px 0 rgba(227, 231, 246, 0.5);
    display: flex;
    background: url(./assets/img/register.png) no-repeat;
  }
}

.import-sty {
  display: flex;
  justify-content: center;
  margin: 100px 100px 0 0;
  .input {
    width: 320px;
  }
  .register {
    width: 320px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #be0009;
    // background-image: linear-gradient(90deg, #59a8ff, #2d6fff);
    color: #ffffff;
    border-radius: 5px;
  }
  .p1 {
    color: #1a1a1a;
    text-align: center;
    padding: 10px;
    text-shadow: 0 0 0 #1a1a1a;
  }
}

.copyright {
  margin-top: 40px;
  text-align: center;
  color: #999;
  font-size: 14px;
}

.retrievesty {
  text-align: right;
  font-size: 18px;
  margin: 5px 28px 0 0;
}
</style>